<template>
  <div>
    <div v-if="!loading && events.length == 0" class="alert alert-danger">
      Không có sự kiện phù hợp
    </div>
    <div class="row list-event" v-else>
      <div v-for="item in events" :key="item.id" class="col-md-4 event-item">
        <h2>
          <b-link
            :to="{
              name: 'EventDetails',
              params: { eventId: item.id, type: type },
            }"
          >
            {{ item.name }}
          </b-link>
        </h2>

        <div class="wrap">
          <b-aspect aspect="16:9">
            <b-link
              :to="{
                name: 'EventDetails',
                params: { eventId: item.id, type: type },
              }"
            >
              <b-img
                v-if="item.eventStreams.length > 0"
                :src="
                  item.eventStreams[item.eventStreams.length - 1].thumbnailUrl
                "
                fluid
              ></b-img>
            </b-link>
          </b-aspect>

          <span class="viewcount">
            <b-icon icon="person-fill"></b-icon> {{ item.viewCount }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventApi from "@/api/eventApi";

export default {
  data() {
    return {
      query: {
        type: -1,
        skip: 0,
        take: 200,
      },
      events: [],
      loading: false,
    };
  },
  computed: {
    title() {
      return this.type == 1 ? "" : "";
    },
  },
  components: {},
  props: {
    type: Number,
  },
  created() {
    this.query.type = this.type;
    this.getEvents();
  },
  watch: {
    skip() {
      this.getEvents();
    },
  },
  methods: {
    getEvents() {
      this.loading = true;
      eventApi
        .getEvents(this.query)
        .then((response) => {
          this.events = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>