import http from "../common/http";

export default {
    getEvents(payload) {
        return http.get(`Event?Type=${payload.type}&Skip=${payload.skip}&Take=${payload.take}`)
    },
    getEventHasVods(date,fromHour,toHour) {
        return http.get(`Event/HasVods?Date=${date}&FromHour=${fromHour}&ToHour=${toHour}`)
    },
    getEventFiles(payload) {
        return http.get(`Event/File?Date=${payload.date}&FromHour=${payload.fromHour}&ToHour=${payload.toHour}&EventId=${payload.eventId}`)
    },
    getEvent(payload) {
        return http.get(`Event/${payload}`)
    }, 
}